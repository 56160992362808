.dashboard-card{
    word-wrap: break-word;
    background-color: #171717 !important;
    background-clip: border-box;
    text-align: center;
    color: #b7a9a9 !important;
    border-radius: 15px !important;
    /* padding: 10px; */
}

.dashboard-icon{
    /* padding: 10px;
    border: 1px solid green;
    border-radius: 50%; */
}

.dashboard-stats{
    margin-top: 40px;
    text-align: left;
}

@media (max-width: 600px) {

    .dashboard-stats{
        margin-top: 20px;
        text-align: left;
    }
    
}

.blueprint-card{
    background-color: #171717 !important;
    background-clip: border-box;
    color: #f2f2f2 !important;
    border-radius: 15px !important;

}

.leaderboard-card{
    background-color: #171717 !important;
    background-clip: border-box;
    color: #373737 !important;
    border-radius: 15px !important;
    margin-bottom: 5px;
}

.grid-row-item-left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #f2f2f2;
    gap: 10px;
}

.grid-row-item-right{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #f2f2f2;
    gap: 5px;
}

.blueprint-avatar{
    height: 12px;
    width: 12px;
}

.card-header{
    border-bottom: 1px solid #504b4b;
    margin-bottom: 10px !important;    
}

.blueprint-button{
    background-color: #323232 !important;
    border-radius: 5px !important;
    width: 100%;
    color: white !important;
    padding: 10px;
    text-align: center;
}

.blueprint-button-text{
    font-size: 0.8rem !important;

}

.blueprint-buttons-container{
    margin-top: 20px !important;
}

@media (max-width: 600px) {
    .MuiGrid-grid-xs-5 {
        flex-basis: 38% !important;
    }

    .dashboard-stats-desc{
        font-size: 0.25rem !important;
    }
}

.MuiAvatar-root  {
    width: 24px !important;
    height: 24px !important;
}

.RaButton-smallIcon-9 {
    font-size: 11px !important;
}


