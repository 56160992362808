/* General Styles */
.App {
  background-color: #111111;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .MuiAppBar-colorSecondary {
    background-color: black;

    .RaAppBar-menuButton-13 {
      background-color: yellow;
    }
  }

  .MuiDrawer-paper {
    background-color: #111111;

    .MuiListItemIcon-root {
      color: white;
    }
  }

  .MuiButton-textPrimary {
    background-color: purple;
    margin: 0;
    color: white;
    padding: 0.5rem 1rem;

    &:hover {
      background-color: blue;
    }
  }

  .MuiFormLabel-root {
    color: magenta;
  }

  .MuiOutlinedInput-input {
    padding: 14.5px 10px !important;
  }

  .MuiInputBase-root {
    color: rgb(225, 209, 209, 0.87) !important;
  }

  .App .MuiFormLabel-root {
    color: #a39ba3 !important;
  }

  ::placeholder {
    color: #707070 !important;
    opacity: 1; /* Firefox */
  }

  ::-ms-input-placeholder {
    color: #707070 !important; /* Edge 12 -18 */
  }
}

/* Layout Styles */
.RaLayout-root-1,
.RaLayout-content-4 {
  background-color: #111111 !important;
}

.dashboard-area {
  width: 85% !important;
  padding: 20px;
  margin-top: 40px;
  background-color: #111111;
  min-height: 100vh;
  transition: margin-left 0.3s;
}

.blueprint-value-container{
  border: 1px solid #504b4b;
  padding: 5px;
  text-align: center;
  margin-top: 5px;
  color: #b7a9a9;
}


/* Sidebar and Menu Styles */
.makeStyles-sidebarMenu-40 {
  margin: 10px;
  background: linear-gradient(to bottom right, #f52aca, #6bcff9);
  border-radius: 10px;
}

.hedge-card {
  background-color: #171717 !important;
  color: #f2f2f2 !important;
  border-radius: 15px !important;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  background-color: #111111;
}

.right-menu {
  display: flex;
  justify-content: space-around;
  gap: 100px;
}

.right-menu-markets {
  display: flex;
  align-items: center;
  gap: 10px;
}

.right-menu-item {
  display: flex;
  align-items: center;
  gap: 7px;
}

.right-menu-profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Typography Styles */
.MuiTypography-body1 {
  font-size: 0.6rem !important;
}

.dashboard-header {
  color: white;
  padding: 20px 0;
}

/* Miscellaneous */
.RaButton-smallIcon-23 {
  font-size: 10px !important;
}

.grid-row-item-rating {
  margin: 10px 0 !important;
}

.MuiListItemIcon-root {
  display: inline-flex;
  min-width: auto !important;
  flex-shrink: 0;
}


/* src/components/MarketSlider.css */

.right-menu-markets {
  width: 100% !important;
}

.right-menu-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background-color: #333;
  border-radius: 10px;
  color: #fff !important;
}

.right-menu-item img {
  border-radius: 50%;
}

.MuiAppBar-colorPrimary {
  color: #fff !important;
  background-color: #171717 !important;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
}

.MuiBox-root{
  padding: 0 !important;
}


.profile-avatar{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}